const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/1.png",
    text: "Architecture 1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/2.png",
    text: "Architecture 2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/3.png",
    text: "Architecture 3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/4.png",
    text: "Architecture 4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/1.png",
    text: "Interior 1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/2.png",
    text: "Interior 2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/3.png",
    text: "Interior 3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/4.png",
    text: "Interior 4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/4a.png",
    text: "Interior 5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/5.png",
    text: "Interior 6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/6.png",
    text: "Interior 7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/7.png",
    text: "Interior 8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/8.png",
    text: "Interior 9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/9.png",
    text: "Interior 10",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/dpsgreaternoida/interiors/10.png",
    text: "Interior 11",
  },
];

export default data;
