import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import "react-awesome-slider/dist/styles.css";
import SEO from "../../seo";
const details = {
  title: "Delhi Public School, Greater Noida",
  address: "Greater Noida",
  client: "DPS Society, New Delhi",
  area: "10,000 Sq. Ft.",
  project: "Administrative Block, Delhi Public School, Greater Noida",
  category: "Institutions | Architecture | School",
  status: "Completed",
  backlink: "/projects/institutions/",
};

export default class Projectdpsgreaternoida extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Delhi Public School, Greater Noida"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
